import { type Component } from 'solid-js'

export const Logo: Component<{ class?: string }> = (props) => {
  return (
    <svg
      role="img"
      fill="none"
      class={props.class}
      viewBox="0 0 407 218"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#3c4da9">
        <path d="m8.77133 206.341h-3.2568v10.969h-1.72187v-10.969h-3.230664v-1.316h8.209334z" />
        <path d="m19.8552 217.31h-1.7125v-5.417h-4.8088v5.417h-1.7126v-12.285h1.7126v5.559h4.8088v-5.559h1.7125z" />
        <path d="m29.464 211.606h-4.1676v4.405h4.9109v1.299h-6.6234v-12.285h6.5817v1.316h-4.8692v3.956h4.1676z" />
        <path d="m40.2027 216.011h4.9697v1.299h-6.6823v-12.285h1.7126z" />
        <path d="m54.0812 211.606h-4.1676v4.405h4.9104v1.299h-6.6229v-12.285h6.5812v1.316h-4.8687v3.956h4.1676z" />
        <path d="m60.0787 212.845h2.902l-1.4176-4.993h-.0511zm3.2812 1.36h-3.6698l-.8865 3.105h-1.7801l3.6869-12.285h1.6631l3.653 12.285h-1.7801z" />
        <path d="m70.5307 206.341v9.67h1.7724c.8433 0 1.5182-.371 2.024-1.111.5068-.74.7598-1.69.7598-2.856v-1.763c0-1.146-.253-2.09-.7598-2.83-.5058-.74-1.1807-1.11-2.024-1.11zm-1.7124 10.969v-12.285h3.4848c1.3272 0 2.4105.491 3.2521 1.476.8401.986 1.2609 2.251 1.2609 3.796v1.747c0 1.552-.4208 2.819-1.2609 3.798-.8416.98-1.9249 1.468-3.2521 1.468z" />
        <path d="m82.114 217.31h-1.714v-12.285h1.714z" />
        <path d="m94.2459 217.31h-1.7219l-4.7579-9.062-.051.017v9.045h-1.7124v-12.285h1.7124l4.7584 9.036.0505-.009v-9.027h1.7219z" />
        <path d="m105.811 215.706c-.294.467-.759.881-1.396 1.241-.639.359-1.462.54-2.469.54-1.282 0-2.3148-.392-3.1007-1.174-.7849-.781-1.176-1.896-1.176-3.341v-3.644c0-1.424.3791-2.527 1.1391-3.308.7583-.781 1.7676-1.172 3.0286-1.172 1.242 0 2.203.32 2.878.962.673.641 1 1.518.978 2.631l-.017.052h-1.612c0-.737-.187-1.31-.561-1.717-.374-.409-.919-.612-1.632-.612-.77 0-1.376.268-1.819.805-.4405.537-.6624 1.319-.6624 2.342v3.661c0 1.046.2297 1.844.6894 2.392.457.549 1.08.823 1.867.823.58 0 1.043-.09 1.389-.27.345-.18.597-.399.754-.657v-2.861h-2.261v-1.248h3.983z" />
        <path d="m116.355 216.011h4.969v1.299h-6.681v-12.285h1.712z" />
        <path d="m126.219 217.31h-1.715v-12.285h1.715z" />
        <path d="m137.944 215.706c-.293.467-.759.881-1.397 1.241-.638.359-1.462.54-2.468.54-1.282 0-2.315-.392-3.102-1.174-.784-.781-1.176-1.896-1.176-3.341v-3.644c0-1.424.38-2.527 1.14-3.308.758-.781 1.768-1.172 3.028-1.172 1.243 0 2.203.32 2.879.962.673.641 1 1.518.979 2.631l-.018.052h-1.612c0-.737-.186-1.31-.561-1.717-.375-.409-.919-.612-1.632-.612-.771 0-1.377.268-1.819.805-.441.537-.662 1.319-.662 2.342v3.661c0 1.046.229 1.844.688 2.392.458.549 1.081.823 1.868.823.58 0 1.042-.09 1.389-.27.345-.18.596-.399.753-.657v-2.861h-2.261v-1.248h3.984z" />
        <path d="m149.831 217.31h-1.714v-5.417h-4.808v5.417h-1.713v-12.285h1.713v5.559h4.808v-5.559h1.714z" />
        <path d="m160.663 206.341h-3.256v10.969h-1.723v-10.969h-3.231v-1.316h8.21z" />
        <path d="m170.557 217.31h-1.714v-12.285h1.714z" />
        <path d="m182.689 217.31h-1.722l-4.758-9.062-.05.017v9.045h-1.714v-12.285h1.714l4.757 9.036.051-.009v-9.027h1.722z" />
        <path d="m197.601 211.849h-4.293v5.461h-1.713v-12.285h6.749v1.316h-5.036v4.202h4.293z" />
        <path d="m207.88 209.463c0-1.068-.231-1.882-.689-2.435-.459-.553-1.112-.831-1.962-.831-.821 0-1.453.278-1.893.831-.443.553-.664 1.367-.664 2.435v3.4c0 1.079.223 1.898.668 2.454.444.558 1.076.836 1.897.836.85 0 1.503-.277 1.958-.83.456-.555.685-1.374.685-2.46zm1.703 3.4c0 1.455-.394 2.592-1.182 3.405-.786.812-1.841 1.219-3.164 1.219-1.304 0-2.342-.407-3.113-1.219-.771-.813-1.156-1.95-1.156-3.405v-3.375c0-1.452.385-2.588 1.156-3.409.771-.822 1.807-1.231 3.105-1.231 1.322 0 2.378.408 3.168 1.227.791.818 1.186 1.957 1.186 3.413z" />
        <path d="m219.539 209.463c0-1.068-.231-1.882-.69-2.435-.457-.553-1.112-.831-1.961-.831-.821 0-1.453.278-1.893.831-.443.553-.663 1.367-.663 2.435v3.4c0 1.079.221 1.898.667 2.454.444.558 1.076.836 1.897.836.849 0 1.503-.277 1.957-.83.458-.555.686-1.374.686-2.46zm1.702 3.4c0 1.455-.393 2.592-1.18 3.405-.788.812-1.842 1.219-3.165 1.219-1.304 0-2.343-.407-3.113-1.219-.771-.813-1.156-1.95-1.156-3.405v-3.375c0-1.452.385-2.588 1.156-3.409.77-.822 1.806-1.231 3.105-1.231 1.321 0 2.377.408 3.168 1.227.791.818 1.185 1.957 1.185 3.413z" />
        <path d="m226.345 206.341v9.67h1.772c.843 0 1.519-.371 2.024-1.111.507-.74.76-1.69.76-2.856v-1.763c0-1.146-.253-2.09-.76-2.83-.505-.74-1.181-1.11-2.024-1.11zm-1.713 10.969v-12.285h3.485c1.327 0 2.411.491 3.252 1.476.84.986 1.262 2.251 1.262 3.796v1.747c0 1.552-.422 2.819-1.262 3.798-.841.98-1.925 1.468-3.252 1.468z" />
        <path d="m243.107 217.31h-1.714v-12.285h1.714z" />
        <path d="m255.237 217.31h-1.721l-4.759-9.062-.05.017v9.045h-1.712v-12.285h1.712l4.758 9.036.051-.009v-9.027h1.721z" />
        <path d="m266.804 215.706c-.293.467-.759.881-1.396 1.241-.639.359-1.461.54-2.468.54-1.283 0-2.316-.392-3.101-1.174-.786-.781-1.176-1.896-1.176-3.341v-3.644c0-1.424.378-2.527 1.138-3.308.759-.781 1.768-1.172 3.03-1.172 1.241 0 2.202.32 2.877.962.673.641 1 1.518.979 2.631l-.018.052h-1.612c0-.737-.186-1.31-.561-1.717-.375-.409-.919-.612-1.632-.612-.771 0-1.376.268-1.819.805-.441.537-.662 1.319-.662 2.342v3.661c0 1.046.229 1.844.689 2.392.457.549 1.08.823 1.868.823.579 0 1.041-.09 1.388-.27.345-.18.597-.399.755-.657v-2.861h-2.262v-1.248h3.983z" />
        <path d="m272.161 210.711h1.823c.708 0 1.231-.187 1.569-.562.338-.372.507-.902.507-1.589 0-.704-.165-1.249-.495-1.636-.328-.389-.828-.583-1.497-.583h-1.907zm0 1.309v5.29h-1.713v-12.278h3.62c1.181 0 2.093.303 2.737.904.646.603.967 1.465.967 2.591 0 .641-.151 1.202-.452 1.683-.3.481-.732.853-1.295 1.118.63.213 1.084.568 1.363 1.063.277.494.419 1.108.419 1.838v1.072c0 .378.034.729.109 1.054.072.328.201.58.388.76v.195h-1.763c-.193-.186-.318-.469-.377-.849-.057-.378-.087-.772-.087-1.177v-1.037c0-.686-.161-1.229-.478-1.63-.318-.399-.759-.597-1.319-.597z" />
        <path d="m287.321 211.606h-4.168v4.405h4.911v1.299h-6.624v-12.285h6.583v1.316h-4.87v3.956h4.168z" />
        <path d="m292.879 206.341v9.67h1.773c.843 0 1.517-.371 2.024-1.111s.759-1.69.759-2.856v-1.763c0-1.146-.252-2.09-.759-2.83s-1.181-1.11-2.024-1.11zm-1.712 10.969v-12.285h3.485c1.327 0 2.411.491 3.252 1.476.84.986 1.261 2.251 1.261 3.796v1.747c0 1.552-.421 2.819-1.261 3.798-.841.98-1.925 1.468-3.252 1.468z" />
        <path d="m304.464 217.31h-1.715v-12.285h1.715z" />
        <path d="m314.232 211.606h-4.168v4.405h4.911v1.299h-6.624v-12.285h6.582v1.316h-4.869v3.956h4.168z" />
        <path d="m326.321 217.31h-1.721l-4.757-9.062-.052.017v9.045h-1.712v-12.285h1.712l4.758 9.036.051-.009v-9.027h1.721z" />
        <path d="m337.155 206.341h-3.258v10.969h-1.721v-10.969h-3.231v-1.316h8.21z" />
        <path d="m350.787 214.223c0-.563-.16-1.027-.482-1.389-.32-.363-.886-.694-1.697-.992-1.152-.361-2.039-.825-2.657-1.391-.618-.57-.928-1.326-.928-2.271 0-.967.342-1.765 1.025-2.392.683-.628 1.56-.94 2.628-.94 1.136 0 2.047.348 2.735 1.047.685.696 1.017 1.572.996 2.622l-.018.051h-1.646c0-.72-.191-1.303-.574-1.746-.381-.443-.885-.667-1.51-.667-.607 0-1.079.192-1.414.574-.333.383-.5.86-.5 1.434 0 .534.178.976.53 1.325.354.349.952.677 1.789.987 1.12.365 1.972.845 2.556 1.436.585.589.879 1.354.879 2.294 0 1-.346 1.798-1.035 2.391-.688.595-1.592.891-2.712.891-1.079 0-2.02-.32-2.817-.962-.799-.641-1.184-1.561-1.156-2.76l.016-.05h1.654c0 .832.22 1.454.663 1.865.44.409.988.615 1.64.615.636 0 1.135-.178 1.493-.535.36-.357.542-.837.542-1.437" />
        <path d="m363.837 205.025v8.124c0 1.363-.384 2.424-1.148 3.19-.765.765-1.757 1.148-2.978 1.148-1.199 0-2.174-.384-2.924-1.152-.751-.767-1.127-1.83-1.127-3.186v-8.124h1.715v8.124c0 .951.213 1.696.644 2.232.429.537.993.806 1.692.806.72 0 1.301-.267 1.741-.802.443-.534.663-1.279.663-2.236v-8.124z" />
        <path d="m369.125 211.151h2.279c.664 0 1.167-.227 1.511-.68.341-.453.514-1.023.514-1.708 0-.692-.172-1.268-.51-1.729-.34-.462-.847-.693-1.515-.693h-2.279zm0 1.316v4.843h-1.712v-12.285h3.991c1.163 0 2.076.342 2.737 1.024.662.683.992 1.581.992 2.696 0 1.119-.33 2.02-.992 2.702-.661.678-1.574 1.02-2.737 1.02z" />
        <path d="m380.041 211.151h2.279c.664 0 1.167-.227 1.509-.68.343-.453.516-1.023.516-1.708 0-.692-.172-1.268-.51-1.729-.342-.462-.847-.693-1.515-.693h-2.279zm0 1.316v4.843h-1.712v-12.285h3.991c1.163 0 2.076.342 2.737 1.024.662.683.992 1.581.992 2.696 0 1.119-.33 2.02-.992 2.702-.661.678-1.574 1.02-2.737 1.02z" />
        <path d="m390.959 216.011h4.969v1.299h-6.681v-12.285h1.712z" />
        <path d="m400.483 211.015h.05l2.566-5.99h1.924l-3.688 7.864v4.421h-1.712v-4.547l-3.62-7.738h1.906z" />
      </g>
      <path
        d="m.561996 197.6v-34.882h7.340134l14.42547 25.357h.0927v-25.357h6.4641v34.882h-7.2011l-14.56397-25.355h-.0928v25.355z"
        fill="#2e8f3e"
      />
      <path
        d="m46.3265 192.699c2.1932 0 4.0079-.806 5.4428-2.416 1.4334-1.611 2.15-3.512 2.15-5.706 0-1.472-.3532-2.864-1.0573-4.176-.7057-1.312-1.6615-2.324-2.8656-3.037-1.2037-.715-2.4199-1.071-3.6469-1.071-1.2115 0-2.4427.354-3.6927 1.06s-2.2323 1.694-2.9443 2.967c-.7136 1.275-1.0698 2.685-1.0698 4.233 0 1.519.3516 2.916 1.0578 4.188.7058 1.274 1.6599 2.251 2.8651 2.934 1.2037.682 2.4568 1.024 3.7609 1.024zm0 5.752c-1.8401 0-3.6114-.354-5.3145-1.058-1.7032-.705-3.1672-1.675-4.3943-2.912-1.227-1.234-2.186-2.572-2.8765-4.014-.9048-1.871-1.3579-3.856-1.3579-5.959 0-2.423.6183-4.735 1.8527-6.936 1.2348-2.201 2.9567-3.92 5.1651-5.155 2.2088-1.234 4.5177-1.853 6.9254-1.853 2.4246 0 4.7319.615 6.9271 1.841 2.1933 1.229 3.8948 2.927 5.1079 5.098 1.2114 2.169 1.8172 4.52 1.8172 7.05 0 1.795-.2959 3.481-.886 5.052-.5906 1.572-1.3968 2.94-2.4156 4.106-1.0203 1.168-2.0792 2.072-3.175 2.716-1.0974.645-2.2828 1.142-3.5557 1.496-1.2735.352-2.5469.528-3.8199.528"
        fill="#2e8f3e"
      />
      <path
        d="m64.3432 197.6v-26.161h5.7527v2.324h.091c.8428-1.227 1.6756-2.067 2.497-2.521.8197-.451 1.8588-.678 3.1182-.678h.5052v6.075c-1.3193.077-2.3922.345-3.2213.805-.8276.46-1.4491 1.128-1.8636 2.001-.4145.875-.6208 2.026-.6208 3.452v14.703z"
        fill="#2e8f3e"
      />
      <path
        d="m82.3792 197.6v-20.592h-3.0599v-5.569h3.0599v-8.721h6.2807v8.721h3.7516v5.569h-3.7516v20.592z"
        fill="#2e8f3e"
      />
      <path
        d="m95.9536 197.6v-34.882h6.2574v10.562c1.949-1.811 4.165-2.716 6.65-2.716 1.902 0 3.719.492 5.454 1.472 1.733.982 2.968 2.248 3.704 3.799.752 1.564 1.128 3.902 1.128 7.016v14.749h-6.282v-13.39c0-1.904-.154-3.373-.461-4.406-.307-1.036-.865-1.88-1.68-2.532-.812-.652-1.855-.979-3.128-.979-1.181 0-2.197.292-3.049.875-.851.584-1.454 1.388-1.805 2.416-.353 1.029-.531 2.601-.531 4.717v13.299z"
        fill="#2e8f3e"
      />
      <path
        d="m122.252 187.613h6.788c.153 1.275.513 2.271 1.081 2.992.86 1.12 2.079 1.68 3.659 1.68 1.381 0 2.508-.437 3.381-1.312.875-.873 1.312-1.97 1.312-3.29 0-.935-.202-1.71-.608-2.323-.408-.613-1.056-1.159-1.945-1.633-.475-.262-2.148-.952-5.016-2.072-1.948-.751-3.401-1.542-4.36-2.37-.959-.829-1.707-1.862-2.243-3.106-.538-1.243-.806-2.592-.806-4.05 0-1.856.445-3.57 1.334-5.142.89-1.572 2.136-2.791 3.74-3.659 1.603-.867 3.294-1.3 5.072-1.3 1.596 0 3.07.314 4.418.944 1.35.629 2.456 1.404 3.314 2.324.859.92 1.495 1.905 1.91 2.956.414 1.052.674 2.289.782 3.717h-6.581c-.199-1.289-.513-2.193-.943-2.716-.66-.797-1.612-1.196-2.853-1.196-1.12 0-2.017.332-2.692 1-.675.667-1.012 1.539-1.012 2.612 0 1.166.399 2.086 1.196 2.761.597.506 1.887 1.093 3.865 1.761 1.98.666 3.406 1.238 4.279 1.713 1.335.721 2.389 1.531 3.165 2.428.774.897 1.371 1.941 1.794 3.129.422 1.188.633 2.512.633 3.969 0 3.667-1.396 6.543-4.188 8.629-2.009 1.502-4.333 2.254-6.971 2.254-1.35 0-2.657-.216-3.922-.644-1.266-.429-2.39-1.024-3.372-1.782-.982-.76-1.78-1.6-2.394-2.522-.613-.92-1.065-1.893-1.356-2.92-.216-.752-.368-1.696-.461-2.832"
        fill="#3c4da9"
      />
      <path
        d="m151.173 197.6v-20.592h-3.06v-5.569h3.06v-8.721h6.28v8.721h3.752v5.569h-3.752v20.592z"
        fill="#3c4da9"
      />
      <path
        d="m185.133 184.461c0-2.438-.733-4.409-2.197-5.912-1.465-1.502-3.295-2.256-5.488-2.256-1.412 0-2.711.356-3.899 1.071-1.19.713-2.126 1.741-2.808 3.084-.682 1.341-1.024 2.74-1.024 4.199 0 2.285.755 4.198 2.267 5.74 1.511 1.541 3.348 2.312 5.511 2.312 2.285 0 4.129-.798 5.533-2.393 1.403-1.595 2.105-3.543 2.105-5.845zm5.936-13.022v26.161h-5.752v-3.244c-1.181 1.288-2.505 2.293-3.969 3.015-1.464.72-3.179 1.08-5.143 1.08-2.346 0-4.462-.563-6.349-1.691-1.887-1.126-3.425-2.764-4.613-4.912-1.19-2.148-1.784-4.593-1.784-7.34 0-2.653.58-5.055 1.737-7.202 1.157-2.146 2.76-3.809 4.809-4.981 2.047-1.173 4.183-1.761 6.407-1.761 1.519 0 2.869.24 4.049.715 1.183.474 2.191 1.046 3.027 1.713.836.668 1.415 1.255 1.736 1.76h.093v-3.313z"
        fill="#3c4da9"
      />
      <path
        d="m196.269 197.6v-26.161h5.754v2.324h.09c.843-1.227 1.675-2.067 2.498-2.521.82-.451 1.858-.678 3.117-.678h.505v6.075c-1.318.077-2.392.345-3.221.805-.827.46-1.448 1.128-1.863 2.001-.414.875-.621 2.026-.621 3.452v14.703z"
        fill="#3c4da9"
      />
      <path
        d="m212.191 197.6v-34.882h17.762v6.053h-11.297v8.513h10.792v6.028h-10.792v14.288z"
        fill="#2e8f3e"
      />
      <path
        d="m245.437 192.699c2.194 0 4.008-.806 5.443-2.416 1.433-1.611 2.151-3.512 2.151-5.706 0-1.472-.354-2.864-1.058-4.176-.706-1.312-1.661-2.324-2.865-3.037-1.204-.715-2.42-1.071-3.647-1.071-1.212 0-2.444.354-3.693 1.06-1.251.706-2.232 1.694-2.944 2.967-.713 1.275-1.071 2.685-1.071 4.233 0 1.519.352 2.916 1.058 4.188.706 1.274 1.661 2.251 2.866 2.934 1.203.682 2.456 1.024 3.76 1.024zm0 5.752c-1.84 0-3.61-.354-5.314-1.058-1.703-.705-3.167-1.675-4.395-2.912-1.227-1.234-2.185-2.572-2.876-4.014-.904-1.871-1.357-3.856-1.357-5.959 0-2.423.617-4.735 1.852-6.936 1.234-2.201 2.957-3.92 5.165-5.155 2.209-1.234 4.519-1.853 6.925-1.853 2.426 0 4.732.615 6.927 1.841 2.193 1.229 3.896 2.927 5.108 5.098 1.212 2.169 1.817 4.52 1.817 7.05 0 1.795-.296 3.481-.885 5.052-.591 1.572-1.397 2.94-2.416 4.106-1.02 1.168-2.079 2.072-3.175 2.716-1.097.645-2.282 1.142-3.556 1.496-1.273.352-2.546.528-3.82.528z"
        fill="#2e8f3e"
      />
      <path
        d="m275.601 192.699c2.195 0 4.008-.806 5.443-2.416 1.433-1.611 2.151-3.512 2.151-5.706 0-1.472-.354-2.864-1.058-4.176-.705-1.312-1.661-2.324-2.865-3.037-1.204-.715-2.42-1.071-3.647-1.071-1.212 0-2.442.354-3.693 1.06s-2.232 1.694-2.944 2.967c-.713 1.275-1.069 2.685-1.069 4.233 0 1.519.35 2.916 1.057 4.188.705 1.274 1.66 2.251 2.865 2.934 1.204.682 2.456 1.024 3.76 1.024zm0 5.752c-1.84 0-3.61-.354-5.313-1.058-1.704-.705-3.168-1.675-4.395-2.912-1.226-1.234-2.185-2.572-2.876-4.014-.905-1.871-1.358-3.856-1.358-5.959 0-2.423.618-4.735 1.853-6.936s2.956-3.92 5.165-5.155c2.208-1.234 4.518-1.853 6.924-1.853 2.426 0 4.732.615 6.928 1.841 2.194 1.229 3.895 2.927 5.108 5.098 1.211 2.169 1.818 4.52 1.818 7.05 0 1.795-.296 3.481-.887 5.052-.591 1.572-1.396 2.94-2.416 4.106-1.02 1.168-2.079 2.072-3.175 2.716-1.097.645-2.282 1.142-3.556 1.496-1.273.352-2.546.528-3.82.528"
        fill="#2e8f3e"
      />
      <path
        d="m298.519 184.301c0 1.503.342 2.91 1.024 4.222s1.641 2.336 2.876 3.072c1.234.737 2.52 1.104 3.854 1.104 1.335 0 2.6-.357 3.796-1.07 1.196-.712 2.148-1.733 2.854-3.06.704-1.327 1.058-2.696 1.058-4.108 0-1.394-.354-2.748-1.058-4.06-.706-1.312-1.66-2.324-2.866-3.037-1.204-.715-2.464-1.071-3.784-1.071-1.35 0-2.634.372-3.854 1.116-1.22.746-2.174 1.766-2.864 3.06-.691 1.298-1.036 2.575-1.036 3.832zm21.26-21.583v34.882h-5.752v-3.199h-.091c-.86 1.274-2.007 2.267-3.441 2.98-1.435.714-3.087 1.07-4.958 1.07-3.605 0-6.72-1.346-9.341-4.036-2.623-2.693-3.935-5.987-3.935-9.885 0-2.193.387-4.129 1.162-5.808.774-1.681 1.729-3.075 2.864-4.188 1.136-1.111 2.37-1.998 3.705-2.658 1.841-.873 3.781-1.312 5.82-1.312 1.688 0 3.137.273 4.351.817 1.21.544 2.33 1.339 3.358 2.382v-11.045z"
        fill="#2e8f3e"
      />
      <path
        d="m340.879 178.572h-6.122c-.182-.812-.486-1.396-.909-1.749-.42-.352-.947-.53-1.575-.53-.706 0-1.285.223-1.737.668-.453.445-.68.981-.68 1.611 0 .521.185.967.553 1.334.368.369 1.32.782 2.854 1.243 2.377.735 4.068 1.4 5.072 1.99 1.005.59 1.798 1.42 2.381 2.485.583 1.067.876 2.321.876 3.763 0 2.561-.879 4.713-2.636 6.453-1.755 1.741-3.945 2.611-6.567 2.611-1.597 0-3.026-.287-4.292-.862-1.265-.576-2.277-1.273-3.037-2.094-.76-.82-1.328-1.71-1.704-2.668-.376-.959-.608-2.114-.701-3.464h6.42c.292 1.226.717 2.09 1.276 2.588.561.5 1.27.748 2.129.748.843 0 1.531-.257 2.059-.771.53-.513.794-1.139.794-1.875s-.241-1.308-.725-1.713c-.483-.408-1.492-.895-3.025-1.461-2.24-.799-3.758-1.451-4.556-1.956-1.151-.722-2.01-1.578-2.576-2.567-.57-.988-.852-2.127-.852-3.416 0-1.564.38-2.995 1.138-4.291.76-1.296 1.834-2.301 3.222-3.014 1.389-.712 2.833-1.071 4.337-1.071 1.349 0 2.573.261 3.671.784 1.096.52 1.992 1.149 2.69 1.887.699.734 1.224 1.528 1.578 2.381.352.851.566 1.837.644 2.956"
        fill="#2e8f3e"
      />
      <path
        d="m345.871 197.6v-34.882h6.466v28.854h10.631v6.028z"
        fill="#3c4da9"
      />
      <path
        d="m367.269 197.6v-20.592h-3.058v-5.569h3.058v-8.721h6.282v8.721h3.752v5.569h-3.752v20.592z"
        fill="#3c4da9"
      />
      <path
        d="m385.216 184.301c0 1.503.341 2.91 1.023 4.222s1.641 2.336 2.876 3.072c1.234.737 2.521 1.104 3.856 1.104 1.334 0 2.6-.357 3.796-1.07 1.196-.712 2.146-1.733 2.853-3.06.704-1.327 1.059-2.696 1.059-4.108 0-1.394-.355-2.748-1.059-4.06-.707-1.312-1.66-2.324-2.865-3.037-1.204-.715-2.466-1.071-3.784-1.071-1.351 0-2.635.372-3.856 1.116-1.219.746-2.174 1.766-2.863 3.06-.691 1.298-1.036 2.575-1.036 3.832zm21.26-21.583v34.882h-5.753v-3.199h-.091c-.86 1.274-2.007 2.267-3.44 2.98-1.435.714-3.088 1.07-4.959 1.07-3.605 0-6.718-1.346-9.34-4.036-2.624-2.693-3.936-5.987-3.936-9.885 0-2.193.387-4.129 1.163-5.808.773-1.681 1.729-3.075 2.864-4.188 1.136-1.111 2.371-1.998 3.705-2.658 1.84-.873 3.782-1.312 5.82-1.312 1.688 0 3.136.273 4.35.817 1.212.544 2.33 1.339 3.358 2.382v-11.045z"
        fill="#3c4da9"
      />
      <path
        d="m203.519.565322 7.618 64.191978 64.194 7.6187-64.194 7.62-7.618 64.192-7.619-64.192-64.192-7.62 64.192-7.6187z"
        fill="#3c4da9"
      />
      <path
        d="m239.155 36.7413-28.075 35.6347 28.075 35.636-35.636-28.0733-35.635 28.0733 28.073-35.636-28.073-35.6347 35.635 28.0734z"
        fill="#3c4da9"
      />
    </svg>
  )
}
