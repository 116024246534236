import { SITE } from '@/config.js'
import { trim } from '@/lib/utils'
import slugify from 'limax'

export const trimSlash = (s: string) => trim(trim(s, '/'))
const createPath = (...params: string[]) => {
  const paths = params
    .map((el) => trimSlash(el))
    .filter((el) => !!el)
    .join('/')
  return '/' + paths + (SITE.trailingSlash && paths ? '/' : '')
}

const BASE_PATHNAME = SITE.basePathname

export const cleanSlug = (text = '') =>
  trimSlash(text)
    .split('/')
    .map((slug) => slugify(slug))
    .join('/')

/** */
export const getCanonical = (path = ''): string | URL => {
  const url = String(new URL(path, SITE.origin))
  if (!SITE.trailingSlash && path && url.endsWith('/')) {
    return url.slice(0, -1)
  } else if (SITE.trailingSlash && path && !url.endsWith('/')) {
    return url + '/'
  }
  return url
}

/** */
export const getPermalink = (slug = '', type = 'page'): string => {
  let permalink: string

  switch (type) {
    case 'page':
    default: {
      permalink = createPath(slug)
      break
    }
  }

  return definitivePermalink(permalink)
}

export const getHomePermalink = (): string => getPermalink('/')

export const getAsset = (path: string): string =>
  '/' +
  [BASE_PATHNAME, path]
    .map((el) => trimSlash(el))
    .filter((el) => !!el)
    .join('/')

const definitivePermalink = (permalink: string): string =>
  createPath(BASE_PATHNAME, permalink)
