import { getPermalink } from '@/lib/permalinks'

export interface Link {
  ariaLabel?: string
  href: string
  icon?: string
  rel?: string
  target?: string
  text: string
}

export interface Links {
  links: Link[]
  title?: string
}

export const headerData = {
  actions: [],
  links: [
    {
      href: getPermalink('/'),
      text: 'Home',
    },
    {
      href: getPermalink('/what-we-do'),
      text: 'What we do',
    },
    {
      href: getPermalink('/product-range'),
      text: 'Product range',
    },
    {
      href: getPermalink('/about-us'),
      text: 'About us',
    },
    {
      href: getPermalink('/contact-us'),
      text: 'Contact us',
    },
  ],
}

export const footerData: {
  links: Record<string, Links>
} = {
  links: {
    column1: {
      links: [
        { href: getPermalink('/'), text: 'Home' },
        { href: getPermalink('/what-we-do'), text: 'What we do' },
        { href: getPermalink('/product-range'), text: 'Product range' },
        { href: getPermalink('/about-us'), text: 'About us' },
        { href: getPermalink('/contact-us'), text: 'Contact us' },
      ],
      title: 'Company',
    },
    column2: {
      links: [
        {
          href: '/product-range#vegetables',
          text: 'IQF Fruit',
        },
        {
          href: '/product-range#fruit',
          text: 'IQF Fruit',
        },
        {
          href: '/product-range#vegetables',
          text: 'IQF Vegetables',
        },
        {
          href: '/product-range#herbs',
          text: 'IQF Herbs',
        },
        {
          href: '/product-range#spices',
          text: 'IQF Spices',
        },
      ],
      title: 'Product rangees',
    },
    column3: {
      links: [
        {
          href: getPermalink(
            '/certs/North-Star-Foods-BRCGS-Certificate-2024.pdf',
          ),
          text: 'BRC Global Standard for Agents and Brokers',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          href: getPermalink(
            '/certs/North-Star-Foods-GB-SA-Proc0-605-CD24.pdf',
          ),
          text: 'Soil Association Organic',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        { href: getPermalink('/cookie-policy'), text: 'Cookie policy' },
        { href: getPermalink('/privacy-policy'), text: 'Privacy policy' },
        { href: getPermalink('/data-policy'), text: 'Data protection policy' },
      ],
      title: 'Certificates & policies',
    },
  },
}

export interface Feature {
  name: string
  description: string
}

export type Features = Array<Feature>

export const IQFFeatures: Features = [
  {
    name: 'Quality Preservation.',
    description: `
      Since the food items are frozen quickly, the cellular structure
      is better preserved, maintaining the taste, texture, and
      nutritional value of the food.
    `,
  },
  {
    name: 'Convenience.',
    description: `
      IQF foods are easy to handle, measure, and prepare because each
      piece is separate from the others.
    `,
  },
  {
    name: 'Reduced Waste.',
    description: `
      Users can defrost and use the exact amount they need without
      having to thaw entire batches, reducing food waste.
    `,
  },
  {
    name: 'Extended Shelf Life.',
    description: `
      The rapid freezing and airtight packaging extend the
      shelf life of the food without the need for preservatives.
    `,
  },
] as const

export const IQFProcess: Features = [
  {
    name: 'Preparation',
    description: 'Food items are cleaned, cut, or prepared as necessary.',
  },
  {
    name: 'Pre-cooling',
    description:
      'The food is often pre-cooled before freezing to remove any heat and to ensure even freezing.',
  },

  {
    name: 'Flash Freezing',
    description:
      'The items are spread out on a conveyor belt or trays, ensuring they do not touch each other. They are then subjected to a blast of extremely cold air, typically at temperatures well below -18°C (0°F). This rapid freezing process is crucial as it stops large ice crystals from forming.',
  },
  {
    name: 'Storage and Packaging',
    description:
      'Once frozen, the items remain separate and free-flowing, making them easy to package, store, and measure out for later use.',
  },
] as const

export interface Product {
  name: string
  image: string
}

export type Products = Array<Product>

export const vegetables: Products = [
  {
    name: 'Asparagus',
    image: '/images/products/white-asparagus.jpg',
  },
  {
    name: 'Avocado',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Bean',
    image: '/images/products/green-beans.jpg',
  },
  {
    name: 'Beetroot',
    image: '/images/products/beetroot-dices.jpg',
  },
  {
    name: 'Broccoli',
    image: '/images/products/broccoli.jpg',
  },
  {
    name: 'Butternut Squash',
    image: '/images/products/pumpkin-diced.jpg',
  },
  {
    name: 'Cauliflower',
    image: '/images/products/cauliflower.jpg',
  },
  {
    name: 'Carrots',
    image: '/images/products/carrot-diced.jpg',
  },
  {
    name: 'Sweetcorn',
    image: '/images/products/sweet-corn-kernel.jpg',
  },
  {
    name: 'Leeks',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Mange Tout',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Mushrooms',
    image: '/images/products/champion-sliced.jpg',
  },
  {
    name: 'Okra',
    image: '/images/products/okra.jpg',
  },
  {
    name: 'Onions',
    image: '/images/products/onion-sliced.jpg',
  },
  {
    name: 'Onions Rings',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Parsnips',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Peas',
    image: '/images/products/peapods.jpg',
  },
  {
    name: 'Peppers',
    image: '/images/products/mixed-pepper-sliced.jpg',
  },
  {
    name: 'Potatoes',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Potatoes - Steak Cut Chips',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Potatoes - French fires',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Pumpkins',
    image: '/images/products/pumpkin-diced.jpg',
  },
  {
    name: 'Spinach',
    image: '/images/products/spinach-diced.jpg',
  },
  {
    name: 'Swede',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Tomatoes',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Turnips',
    image: '/images/default-product-image.png',
  },
]

export const fruits: Products = [
  {
    name: 'Apple',
    image: '/images/products/apple-chunk.jpg',
  },
  {
    name: 'Apricots',
    image: '/images/products/apricot-skin-on.jpg',
  },
  {
    name: 'Banana',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Blackcurrants',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Blackberries',
    image: '/images/products/blackberry.jpg',
  },
  {
    name: 'Blueberries (Wild & Cultivated)',
    image: '/images/products/blueberry.jpg',
  },
  {
    name: 'Coconut',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Cranberries',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Cherries',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Fig',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Gooseberry',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Kiwi',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Lemon - Zest, Peel, Juice & Leaves',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Lime - Zest, Peel, Juice & Leaves',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Mango',
    image: '/images/products/mango-halves.jpg',
  },
  {
    name: 'Melon',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Orange - Zest, Peel, Juice & Leaves',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Passion fruit',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Pear',
    image: '/images/products/pear-diced.jpg',
  },
  {
    name: 'Pineapple',
    image: '/images/products/pineapple-diced.jpg',
  },
  {
    name: 'Pomegranate',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Raspberries',
    image: '/images/products/raspberry-whole.jpg',
  },
  {
    name: 'Rhubarb',
    image: '/images/products/rhubarb.jpg',
  },
  {
    name: 'Sloes',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Strawberries',
    image: '/images/products/strawberry-honey.jpg',
  },
]

export const herbs: Products = [
  {
    name: 'Bamboo Shoots',
    image: '/images/products/bamboo-shoots.jpg',
  },
  {
    name: 'Birdseye Chilli Peppers',
    image: '/images/products/red-birdseye.jpg',
  },
  {
    name: 'Cayenne Chilli Peppers',
    image: '/images/products/cayenne-chillies.jpg',
  },
  {
    name: 'Chinese Generic Chilli Peppers',
    image: '/images/products/chinese-chillies.jpg',
  },
  {
    name: 'Habanero Chilli Peppers',
    image: '/images/products/green-chilli.jpg',
  },
  {
    name: 'Jalapeño Chilli Peppers',
    image: '/images/products/jalapeno.jpg',
  },
  {
    name: 'Jinta Chilli Peppers',
    image: '/images/products/jinta.jpg',
  },
  {
    name: 'Scotch Bonnet Chilli Peppers',
    image: '/images/products/scotch-bonnet.jpg',
  },
  {
    name: 'Curry Leaf',
    image: '/images/products/curry-leaf.jpg',
  },
  {
    name: 'Garlic',
    image: '/images/products/garlic-cloves.jpg',
  },
  {
    name: 'Ginger',
    image: '/images/products/ginger-flake.jpg',
  },
  {
    name: 'Basil',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Coriander',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Dill',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Fennel',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Marjoram',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Oregano',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Parsley',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Rosemary',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Sage',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Thyme',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Lemon Grass',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Lime Leaf',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Rice',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Rose Hips',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Sea Buckthorn',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Starch (Potato)',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Truffles - White & Black',
    image: '/images/default-product-image.png',
  },
  {
    name: 'Water Chestnuts',
    image: '/images/products/water-chestnut.jpg',
  },
]
